import {
  faArrowDown,
  faArrowToTop,
  faArrowUp,
  faChevronLeft,
  faChevronRight,
  faCopy,
  faDesktop,
  faLink,
  faMobile,
  faPen,
  faPlus,
  faTablet,
  faSpinner,
  faTrashAlt,
  faArrowRight,
  faExternalLink,
  faPencil,
  faEye,
} from "@fortawesome/pro-light-svg-icons";
import { faExpandAlt } from "@fortawesome/pro-regular-svg-icons";
import mainLogo from "../../../../assets/images/vfi-logo.png";
import { faClose, faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import "./EditPage.scss";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import Axios from "axios";
import env from "../../../../environment.json";
import OptionsList from "../../../../assets/OptionsList/OptionsList";
import { capitalize } from "../../../../assets/helperFunctions";
import OneMedia, { OneMediaMO } from "../../../../assets/PageModules/OneMedia";
import MyUnloadComponent from "../../../../assets/MyUnloadComponent";
import VfiCKeditor, { defaultCkToolbar } from "../../../../assets/VfiCKeditor/VfiCKeditor";
import ReadAlso from "../../../../assets/PageModules/ReadAlso/ReadAlso";
import TextAndExpand, {
  moduleOptions as TextAndExpandMO,
} from "../../../../assets/PageModules/TextAndExpand/TextAndExpand";
import RelatedAndText from "../../../../assets/PageModules/RelatedAndText/RelatedAndText";
import MediaWithSubtextAndText, {
  moduleOptions as MediaWithSubtextAndTextMO,
} from "../../../../assets/PageModules/MediaWithSubtextAndText/MediaWithSubtextAndText";
import VfiCarousel, { EditModule as VfiCarouselMO } from "../../../../assets/PageModules/VfiCarousel";
import PageHistory from "../PageHistory/PageHistory";
import { cloneDeep, isEqual } from "lodash";
import languages from "../language.json";
import VisitFaroeIslandsTop, {
  EditModule as VisitFaroeIslandsTopMO,
} from "../../../../assets/PageModules/VisitFaroeIslandsTop";
import axios from "axios";
import CKEditorExcerpt, {
  moduleOptions as CKEditorExcerptMO,
} from "../../../../assets/PageModules/CKEditorExcerpt/CKEditorExcerpt";
import MediaGrid from "../../../../assets/PageModules/MediaGrid/MediaGrid";
import Select from "react-select";
import SocialModule from "../../../../assets/PageModules/SocialModule/SocialModule";
import WhatsonModule2 from "../../../../assets/PageModules/WhatsonModule2/WhatsonModule2";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import Calendar from "react-calendar";
import "../../../../assets/CustomCalendar/CustomCalendar.scss";
import { RenderPageModules } from "../../Overlay/PageModuleChoose/PageModuleChoose";
import Newsletter, { NewsletterMO } from "../../../../assets/PageModules/Newsletter";
import { SaveTemplate } from "../Template";
import ReactPlayer from "react-player";
import "../../../../assets/PageModules/WhatsonFilterShow/WhatsonGrid.scss";
import noImage from "../../../../assets/images/empty.jpg";
import WhatsonEditModule from "../../../../assets/PageModules/WhatsonFilterShow/EditModule";
import DownloadModule, { EditModule as DownloadModuleMO } from "../../../../assets/PageModules/DownloadModule";
import RightSideInfo from "../../../../assets/RightSideInfo/RightSideInfo";
import ServiceTabs from "../../../../assets/PageModules/ServiceTabs";
import Analytics from "../../Analytics/Analytics";
import Location from "../../../../assets/PageModules/Location/Location";
import CardType from "../../../../assets/PageModules/WhatsonFilterShow/CardType";
import ListType from "../../../../assets/PageModules/WhatsonFilterShow/ListType";
import GoogleMapSearchBox from "../../../../assets/GoogleMapSearchBox";
import ReactMapGl, { Marker, Popup } from "react-map-gl";
import ReactMapGlOpen, { defaultViewportValue, getAddress } from "../../../../assets/ReactMapGlOpen";
import mapMarkers from "../../../../assets/images/map-markers/map-markers";
import { GlobalToolTipController } from "../../../ToolTips/GlobalToolTip";
import ToolTip from "../../../ToolTips/ToolTip";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import DeleteModule from "./components/DeleteModule/DeleteModule";
import PageFooterDisplay from "../PageFooterDisplay";
import AutoRelated from "../AutoRelated";
import Breadcrumbs from "../Breadcrumbs";
import { useRouterStore } from "src/Components/Router/routerStore";
import { pagesStore } from "src/Components/ZustandStores/pages-store/pagesStore";
import ChangeURLSlugModal from "./components/ChangeURLSlugModal";

const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

function moreZero(val) {
  return val < 10 ? "0" + val : val.toString();
}

function customDateDisplay(date) {
  date = new Date(date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    months[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear() +
    " at " +
    moreZero(date.getHours()) +
    ":" +
    moreZero(date.getMinutes())
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let hours = [],
  minutes = [];
for (let i = 0; i < 24; i++) {
  hours.push({
    label: i < 10 ? "0" + i : i.toString(),
    value: i < 10 ? "0" + i : i.toString(),
  });
}
for (let i = 0; i < 60; i += 5) {
  minutes.push({
    label: i < 10 ? "0" + i : i.toString(),
    value: i < 10 ? "0" + i : i.toString(),
  });
}

export default function EditPage(props) {
  const [data, setData] = useState(props.data);

  const { addOverlay } = overlayStore((state) => state);

  const { route, navByLink, back } = useRouterStore();
  const { setPagesStore } = pagesStore();

  const isArticle = data.page.article_id !== null;

  const [chosenLanguages, setChosenLanguages] = useState(Object.keys(props.data.languages));
  const [selectedLanguage, setSelectedLanguage] = useState(chosenLanguages[0]);
  const [editSlug, setEditSlug] = useState(false);
  const [newSlug, setNewSlug] = useState(null);

  useEffect(() => {
    setSelectedLanguage(
      Object.values(props.data.languages).reduce(
        (prev, curr) => {
          return curr.order_by < prev.order_by ? curr : prev;
        },
        { order_by: 999999, short: "Nada" }
      ).short
    );

    let c = cloneDeep(contentData);
    setContentData([]);
    setTimeout(() => {
      setContentData(c);
    }, 0);
  }, []);

  const [sideOption, setSideOption] = useState("general");
  const [subGeneral, setSubGeneral] = useState(null);
  const [locations, setLocations] = useState([]);
  const [menues, setMenues] = useState([]);
  const [siteName, setSiteName] = useState();
  const [contentData, setContentData] = useState(cloneDeep(props.data.b_modules));

  const [contentEditEnabled, setContentEditEnabled] = useState(false);
  const [contentBeforeEdit, setContentBeforeEdit] = useState(cloneDeep(contentData));
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const [boxWidth, setBoxWidth] = useState(undefined);
  const [boxHeight, setBoxHeight] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const saveButtonRef = useRef(null);

  const [moduleSearch, setModuleSearch] = useState("");

  const [rightSideInfo, setRightSideInfo] = useState("");

  const openInfo = (info) => {
    setRightSideInfo(info);
  };

  const { setConfirmBeforeNavigate, openOverlay } = props;

  useEffect(() => {
    window.onpopstate = function (event) {
      if (event.state) {
        editPage(event.state.page_id);
      }
    };
  }, []);

  useEffect(() => {
    setListIsOpen(false);

    return () => {
      if (setConfirmBeforeNavigate) setConfirmBeforeNavigate("");
    };
  }, [setConfirmBeforeNavigate]);
  let slugs = {};

  Object.keys(props.data.languages).forEach((key) => {
    slugs[key] = props.data.languages[key].url_slug;
  });
  const [featureImage, setFeatureImage] = useState({
    id: props.data.page.feature_media,
    extension: props.data.page.feature_media_extension,
    alt: props.data.page.feature_media_alt,
    file_name: props.data.page.feature_media_file_name,
  });
  const [listIsOpen, setListIsOpen] = useState(false);
  const [optionsListOptions, setOptionsListOptions] = useState([]);
  const [optionsListPos, setOptionsListPos] = useState({ x: 0, y: 0 });
  const [optionsListBGBlur, setOptionsListBGBlur] = useState(false);
  const [closeWhenOutClick, setCloseWhenOutClick] = useState(true);
  const [resize, setResize] = useState(false);
  const [customElements, setCustomElements] = useState(false);
  const pageContentRef = useRef(null);
  const [pageContentFromTop, setPageContentFromTop] = useState(0);

  const defaultModuleOptions = (indexInEdit) => [
    {
      is: "choice",
      icon: faCopy,
      text: "Duplicate Module",
      onClick: () => {
        let maxKey = 0;
        contentData[selectedLanguage].forEach((e) => {
          maxKey = Math.max(maxKey, e.key);
        });
        const tmpData = cloneDeep(contentData[selectedLanguage][indexInEdit]);
        tmpData.key = maxKey + 1;
        let newContentData = cloneDeep(contentData);
        newContentData[selectedLanguage].splice(indexInEdit + 1, 0, tmpData);
        setContentData(newContentData);
      },
    },
    {
      is: "choice",
      icon: faArrowUp,
      text: "Move Module Up",
      onClick: () => {
        let newContentData = cloneDeep(contentData);
        const tmpData = newContentData[selectedLanguage][indexInEdit];
        newContentData[selectedLanguage].splice(indexInEdit, 1);
        newContentData[selectedLanguage].splice(indexInEdit - 1, 0, tmpData);
        setContentData(newContentData);
      },
      style: {},
      appear: () => indexInEdit !== 0,
    },
    {
      is: "choice",
      icon: faArrowDown,
      text: "Move Module Down",
      onClick: () => {
        let newContentData = cloneDeep(contentData);
        const tmpData = newContentData[selectedLanguage][indexInEdit];
        newContentData[selectedLanguage].splice(indexInEdit, 1);
        newContentData[selectedLanguage].splice(indexInEdit + 1, 0, tmpData);
        setContentData(newContentData);
      },
      style: {},
      appear: () => indexInEdit !== contentData[selectedLanguage].length - 1,
    },
    {
      is: "choice",
      icon: faTrashAlt,
      text: "Delete Module",
      onClick: () => {
        addOverlay("card", (close) => {
          return (
            <DeleteModule
              close={close}
              deleteModule={() => {
                let newContentData = cloneDeep(contentData);
                newContentData[selectedLanguage].splice(indexInEdit, 1);
                setContentData(newContentData);
                setModuleIndexInEdit(undefined);
              }}
            />
          );
        });
      },
      style: { color: "red" },
    },
  ];
  const setOptionsList = (
    options,
    pos = undefined,
    isCustom = false,
    closeWhenOutClick = true,
    forceOpenList = true,
    backgroundBlurBlur = false
  ) => {
    setCloseWhenOutClick(closeWhenOutClick);
    setListIsOpen(forceOpenList || listIsOpen);
    setOptionsListOptions(options);
    setOptionsListBGBlur(backgroundBlurBlur);
    if (pos !== undefined) setOptionsListPos(pos);
    setCustomElements(isCustom);
  };
  const clickPosition = (e, offset = { x: 0, y: 0 }) => {
    return {
      x: e.pageX + (offset.x ? parseInt(offset.x) : 0),
      y: e.pageY + (offset.y ? parseInt(offset.y) : 0),
    };
  };

  const [fullScreen, setFullScreen] = useState(props.screenSize ? props.screenSize : false);
  useEffect(() => {
    var checkCurrentWidth = window.innerWidth;
    const checkCurrentHeight = window.innerHeight;
    if (fullScreen === false) {
      checkCurrentWidth = checkCurrentWidth - 605;
      var setWidth = checkCurrentWidth >= 1920 ? 1920 : checkCurrentWidth;
      setWidth = setWidth < 700 ? 700 : setWidth;
      setBoxWidth(setWidth);
      setBoxHeight(checkCurrentHeight - 180);
    } else if (fullScreen === "full_screen") {
      setBoxWidth(checkCurrentWidth);
      setBoxHeight(checkCurrentHeight);
    }

    const funk = () => setResize(resize ? false : true);
    window.addEventListener("resize", funk);

    return () => window.removeEventListener("resize", funk);
  }, [fullScreen, resize]);
  const [pageHistory, setPageHistory] = useState([]);
  useEffect(() => {
    Axios.get(env.protocol + env.env + "/api/public/GetScreenLocations.php")
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (sideOption === "history")
      Axios.get(env.protocol + env.env + "/api/public/pages/GetPageHistory.php?id=" + data.page.id)
        .then((response) => {
          setPageHistory(response.data);
        })
        .catch((error) => console.error(error));
  }, [contentBeforeEdit, data, sideOption]);

  const [addLanguageChoices, setAddLanguageChoices] = useState([]);
  const [allPages, setAllPages] = useState([]);
  useEffect(() => {
    axios
      .get(
        env.protocol +
          env.env +
          "/api/public/pages/GetAllPagesInSite.php?site=" +
          props.data.page.site_id +
          "&language=" +
          selectedLanguage +
          "&list&all"
      )
      .then((response) => {
        setAllPages(response.data.allPages);
      });
  }, []);

  useEffect(() => {
    Axios.post(
      env.protocol +
        env.env +
        "/api/public/pages/GetAllPagesInSite.php?published&site=" +
        props.data.page.site_id +
        "&language=" +
        selectedLanguage +
        (props.data.rootPageId ? `&rootPage=${props.data.rootPageId}` : "") +
        "&maxLevel=3"
    ).then((response) => {
      if (response.data.hierarchy)
        setSiteName(
          props.data.rootpageId ? response.data.hierarchy.children[0].pl_title : response.data.hierarchy.name
        );
      setMenues(response.data.hierarchy.children[0].children);
    });
  }, [menues.length, selectedLanguage, chosenLanguages, props.data.page.site_id]);

  useEffect(() => {
    Axios.post(env.protocol + env.env + "/api/public/pages/GetAllAvailableLanguages.php")
      .then((response) => {
        let unAddedLanguages = response.data.filter((e) => {
          for (let i = 0; i < chosenLanguages.length; i++) {
            if (e.short === chosenLanguages[i]) return false;
          }
          return true;
        });
        if (isArticle) {
          unAddedLanguages = unAddedLanguages.filter((e) => ["en", "fo"].includes(e.short));
        }
        unAddedLanguages.sort((a, b) => {
          return a.order_by > b.order_by;
        });
        unAddedLanguages.forEach((e, i) => {
          unAddedLanguages[i] = {
            is: "choice",
            text: capitalize(e.what_language),
            onClick: () => {
              let currentChosenLanguages = JSON.parse(JSON.stringify(chosenLanguages));
              currentChosenLanguages.push(e.short);
              setChosenLanguages(currentChosenLanguages);
              setIsLoading(true);
              Axios.post(env.protocol + env.env + "/api/secured/pages/AddLanguageToPage", {
                user: sessionStorage.getItem("vfiUser"),
                pageId: props.data.page.id,
                langId: e.id,
                duplicateLang: selectedLanguage,
              })
                .then((response) => {
                  setIsLoading(false);

                  setContentData(response.data.page.b_modules);

                  let copyData = Object.assign({}, data);
                  copyData.languages[response.data.newLanguage.short] = response.data.newLanguage;
                  copyData.history[response.data.newLanguage.short] = [response.data.newLanguage];
                  setData(copyData);
                })
                .catch((error) => console.error(error));
            },
            style: {
              display: "flex",
            },
          };
        });
        setAddLanguageChoices(unAddedLanguages);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [chosenLanguages, props.data.page.id, data, selectedLanguage]);

  const changePageValue = (label, value, afterFunc, langShort = null) => {
    Axios.post(env.protocol + env.env + "/api/secured/pages/ChangePageValue", {
      id: props.data.page.id,
      label,
      value,
      langShort,
      user: sessionStorage.getItem("vfiUser"),
    })
      .then((response) => {
        if (afterFunc) {
          afterFunc(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeArticleValue = (label, value, afterFunc) => {
    Axios.post(env.protocol + env.env + "/api/secured/infoscreen/infoscreenArticles/ChangeArticleValues", {
      id: props.data.article.id,
      label,
      value,
      user: sessionStorage.getItem("vfiUser"),
    })
      .then((response) => {
        if (afterFunc) {
          afterFunc(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [randomNumber, setRandomNumber] = useState(0);

  const [newModuleIndex, setNewModuleIndex] = useState(undefined);
  const [moduleIndexInEdit, setModuleIndexInEdit] = useState(undefined);
  const [moduleKeyInEdit, setModuleKeyInEdit] = useState(undefined);
  const [innerIndex, setInnerIndex] = useState(undefined);
  const prevNewData = usePrevious(props.newData);
  useEffect(() => {
    if (prevNewData === props.newData) return;

    if (newModuleIndex !== undefined) {
      let copyContentDataLang = cloneDeep(contentData[selectedLanguage]);
      let newData = props.newData;
      let maxKey = 0;

      copyContentDataLang.forEach((element) => {
        if (element.key > maxKey) maxKey = element.key;
      });

      if (newData.constructor === Object) {
        newData.key = maxKey + 1;
        copyContentDataLang.splice(newModuleIndex, 0, newData);
      } else {
        if (newData.constructor === Array) {
          for (let i = 0; i < newData.length; i++) {
            newData[i].key = maxKey + i + 1;
          }
          copyContentDataLang.splice(newModuleIndex, 0, ...newData);
        } else {
        }
      }

      setContentData({
        ...contentData,
        [selectedLanguage]: copyContentDataLang,
      });
      setNewModuleIndex(undefined);
      setModuleIndexInEdit(undefined);
      return;
    }
    if (moduleIndexInEdit !== undefined) {
      if (contentData[selectedLanguage][moduleIndexInEdit] !== undefined) {
        const moduleType = contentData[selectedLanguage][moduleIndexInEdit].type;
        if (moduleType === "media" || moduleType === "media_with_subtext_and_text") {
          let editContentData = cloneDeep(contentData);
          editContentData[selectedLanguage][moduleIndexInEdit].media = {
            id: props.newData.id,
            file_name: props.newData.file_name,
            alt: props.newData.alt,
            extension: props.newData.fileExtension,
            src: props.newData.medium.large,
          };
          setContentData(editContentData);
        }
        if (moduleType === "related_and_text") {
          let editContentData = cloneDeep(contentData);
          editContentData[selectedLanguage][moduleIndexInEdit].pageRelatedId = props.newData.id;
          editContentData[selectedLanguage][moduleIndexInEdit].shared = props.newData.shared;
          setContentData(editContentData);
        }

        if (moduleType === "read_also") {
          let editContentData = cloneDeep(contentData);
          editContentData[selectedLanguage][moduleIndexInEdit].pageId = props.newData.id;
          editContentData[selectedLanguage][moduleIndexInEdit].shared = props.newData.shared;
          setContentData(editContentData);
        }

        if (moduleType === "carousel") {
          let editContentData = cloneDeep(contentData);

          if (props.newData !== null) {
            if (moduleKeyInEdit === "medium") {
              let newData = props.newData;
              if (Array.isArray(newData)) {
                newData = newData[0];
              }
              const theMedium = editContentData[selectedLanguage][moduleIndexInEdit].media;
              theMedium[innerIndex] = {
                ...theMedium[innerIndex],
                ...newData,
                src: newData.medium.large,
              };
            }
            if (moduleKeyInEdit === "media_add") {
              props.newData.forEach((element) => {
                let maxKey = 0;
                editContentData[selectedLanguage][moduleIndexInEdit].media.forEach((element) => {
                  if (element.key > maxKey) maxKey = element.key;
                });

                editContentData[selectedLanguage][moduleIndexInEdit].media.push({
                  key: maxKey + 1,
                  id: element.id,
                  file_name: element.file_name,
                  alt: element.alt,
                  extension: element.fileExtension,
                  show_link: true,
                });

                editContentData[selectedLanguage][moduleIndexInEdit].languages.push({
                  headline: "",
                  excerpt: "",
                  button_active: false,
                  button_text: "",
                  button_direct: "",
                });
              });
            }
            if (moduleKeyInEdit === "page_change") {
              const pageId = props.newData.id;
              const mIndex = moduleIndexInEdit;
              const iIndex = innerIndex;
              Axios.get(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true&id=" + pageId)
                .then((response) => {
                  let editContentData = cloneDeep(contentData);
                  editContentData[selectedLanguage][mIndex].languages[iIndex].button_page_direct = pageId;
                  const responseL = response.data.languages;
                  if (responseL) {
                    editContentData[selectedLanguage][mIndex].languages[iIndex].button_direct = responseL.path;
                  } else {
                    editContentData[selectedLanguage][mIndex].languages[iIndex].button_direct = "";
                  }
                  setContentData(editContentData);
                })
                .catch((error) => console.error(error));
            }
          }
          setContentData(editContentData);
        }

        if (moduleType === "visit_faroe_islands_top") {
          let copyAll = cloneDeep(contentData);
          let copyModule = copyAll[selectedLanguage][moduleIndexInEdit];

          let change = false;
          if (moduleKeyInEdit === "page") {
            copyModule.data[innerIndex].button_direct = props.newData.id;
            copyModule.data[innerIndex].shared = props.newData.shared;
            change = true;
          }

          if (moduleKeyInEdit === "medium") {
            copyModule.data[innerIndex].media = {
              id: props.newData.id,
              alt: props.newData.alt,
              file_name: props.newData.file_name,
              extension: props.newData.fileExtension,
              src: props.newData.medium.large,
              medium: props.newData.medium,
            };
            change = true;
          }

          if (moduleKeyInEdit === "bottom_icon") {
            copyModule.data[innerIndex].bottom_icon = props.newData.id;
            change = true;
          }

          if (change) setContentData(copyAll);
        }

        if (moduleType === "serviceTabs") {
          let copy = cloneDeep(contentData);
          let content = copy[selectedLanguage][moduleIndexInEdit];
          if (moduleKeyInEdit === "media_icon") {
            content.selectedTabs[innerIndex].icon = {
              is: "icon",
              id: props.newData.id,
              alt: props.newData.alt,
              file_name: props.newData.file_name,
              extension: props.newData.fileExtension,
            };
          }
          setContentData(copy);
        }

        if (moduleType === "media_grid") {
          let copy = cloneDeep(contentData);
          let content = copy[selectedLanguage][moduleIndexInEdit];

          const maxKey = content.media.reduce((acc, shot) => (acc = acc > shot.key ? acc : shot.key), 0);
          if (moduleKeyInEdit === "page_add") {
            content.media.push({
              is: "media",
              id: props.newData.feature_media,
              src: props.newData.medium ? props.newData.medium.large : undefined,
              link_option: "page",
              page_id: props.newData.id,
              shared: props.newData.shared,
            });
            content.languages.push({});
          }
          if (moduleKeyInEdit === "media_icon") {
            content.media[innerIndex] = {
              ...content.media[innerIndex],
              icon: {
                is: "icon",
                id: props.newData.id,
                alt: props.newData.alt,
                file_name: props.newData.file_name,
                extension: props.newData.fileExtension,
              },
            };
          }
          if (moduleKeyInEdit === "media_add") {
            props.newData.forEach((e, i) => {
              content.media.push({
                is: "media",
                id: e.id,
                alt: e.alt,
                file_name: e.file_name,
                extension: e.fileExtension,
                key: maxKey + 1 + i,
              });
              content.languages.push({});
            });
            content.preset_number = 1;
          }
          if (moduleKeyInEdit === "add_page") {
            axios
              .get(
                env.protocol +
                  env.env +
                  "/api/public/pages/GetPage.php?update=true&id=" +
                  props.newData.id +
                  "&shared=" +
                  (props.newData.shared ? "1" : "0")
              )
              .then((response) => {
                const title = response.data.languages ? response.data.languages.title : "";

                content.media.push({
                  is: "page",
                  page_id: props.newData.id,
                  shared: props.newData.shared,
                  id: response.data.page.feature_media,
                  alt: response.data.page.feature_media_alt,
                  file_name: response.data.page.feature_media_file_name,
                  extension: response.data.page.feature_media_extension,
                  key: maxKey + 1,
                  link_option: "page",
                });
                content.languages.push({ title });
                setContentData(copy);
                setRandomNumber(randomNumber + 1);
                content.preset_number = 1;
              })
              .catch((error) => console.error(error));
          }
          if (moduleKeyInEdit === "update_media" || moduleKeyInEdit === "medium") {
            content.media[innerIndex] = {
              ...content.media[innerIndex],
              id: props.newData.id,
              alt: props.newData.alt,
              file_name: props.newData.file_name,
              extension: props.newData.fileExtension,
              src: props.newData.medium.large,
              medium: props.newData.medium,
            };
          }

          if (moduleKeyInEdit === "update_page") {
            axios
              .get(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true&id=" + props.newData.id)
              .then((response) => {
                // No page info is used now, so this axios is pointless atm...
                content.media[innerIndex] = {
                  ...content.media[innerIndex],
                  is: "page",
                  page_id: props.newData.id,
                };
                setContentData(copy);
                setRandomNumber(randomNumber + 1);
              })
              .catch((error) => console.error(error));
          }

          if (moduleKeyInEdit === "choose_template") {
            content.preset_number = props.newData;
          }
          setContentData(copy);
        }

        if (moduleType === "whatson_grid") {
          let copy = cloneDeep(contentData);
          let content = copy[selectedLanguage][moduleIndexInEdit];

          if (moduleKeyInEdit === "whatson_add") {
            content.whatson_list = [...content.whatson_list, ...props.newData[0].whatson_list];
          }

          setContentData(copy);
        }

        if (moduleType === "whatson_card") {
          let copy = cloneDeep(contentData);
          let content = copy[selectedLanguage][moduleIndexInEdit];

          if (moduleKeyInEdit === "whatson_add") {
            content.whatson_list = [...content.whatson_list, ...props.newData[0].whatson_list];
          }

          setContentData(copy);
        }

        if (moduleType === "whatson_list") {
          let copy = cloneDeep(contentData);
          let content = copy[selectedLanguage][moduleIndexInEdit];

          if (moduleKeyInEdit === "whatson_add") {
            content.whatson_list = [...content.whatson_list, ...props.newData[0].whatson_list];
          }

          setContentData(copy);
        }

        if (contentData[selectedLanguage][moduleIndexInEdit].type === "download_module") {
          let copy = cloneDeep(contentData);
          let content = copy[selectedLanguage][moduleIndexInEdit];

          if (moduleKeyInEdit === "image") {
            content.data[innerIndex].medium = props.newData.medium;
          }

          if (moduleKeyInEdit === "download") {
            content.data[innerIndex].medium_download = props.newData.medium;
          }

          setContentData(copy);
        }
      }

      if (moduleIndexInEdit === "featureMedia") {
        Axios.post(env.protocol + env.env + "/api/secured/pages/ChangePageValue", {
          user: sessionStorage.getItem("vfiUser"),
          id: props.data.page.id,
          label: "feature_media",
          value: props.newData.id,
        })
          .then((response) => {
            // Take as if it was a success
            Axios.post(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true", {
              page: props.data.page.id,
            })
              .then((response) => {
                setFeatureImage({
                  id: response.data.page.feature_media,
                  alt: response.data.page.feature_media_alt,
                  file_name: response.data.page.feature_media_file_name,
                  extension: response.data.page.feature_media_extension,
                });

                data.page.feature_image_dir = response.data.page.feature_image_dir;
              })
              .catch((error) => console.error(error));
          })
          .catch((error) => console.error(error));
      }
    }
    setNewModuleIndex(undefined);
    setModuleIndexInEdit(undefined);
  }, [
    props.newData,
    chosenLanguages,
    contentData,
    innerIndex,
    moduleIndexInEdit,
    moduleKeyInEdit,
    newModuleIndex,
    prevNewData,
    props.data.page.id,
    randomNumber,
    selectedLanguage,
  ]);

  const addIn = useCallback(
    (index, element) => {
      if (element.openOverlay) {
        openOverlay(element.openOverlay, element.data);
        setListIsOpen(false);
        setNewModuleIndex(index);
      } else {
        let copyData = cloneDeep(contentData);

        if (!element.key) {
          let maxKey = 0;
          if (copyData[selectedLanguage]) {
            copyData[selectedLanguage].forEach((e) => {
              maxKey = Math.max(maxKey, e.key);
            });
            element.key = maxKey + 1;
          }
        }

        copyData[selectedLanguage].splice(index, 0, element);
        setContentData(copyData);
        setListIsOpen(false);
        setTimeout(() => {
          if (pageContentRef.current) {
            const newNode = pageContentRef.current.childNodes[isArticle ? index : index + 1];
            pageContentRef.current.scroll({
              top: newNode.offsetTop - 200,
              left: 0,
              behavior: "smooth",
            });
          }
        }, 0);
      }
    },
    [contentData, selectedLanguage, openOverlay]
  );

  const addModuleBetween = (e, moduleIndex, ModuleOptions) => {
    setModuleIndexInEdit(moduleIndex);
    const mo = (
      <ModuleOptions
        index={moduleIndex}
        chosenLanguages={chosenLanguages}
        addIn={addIn}
        search={moduleSearch}
        setSearch={setModuleSearch}
        isArticle={isArticle}
        language={selectedLanguage}
      />
    );
    let f = { pageX: e.pageX, pageY: e.pageY };
    f.pageX = f.pageX + 20;
    setOptionsList(mo, clickPosition(f), true);
  };

  let sideOptionContent = "";

  const handleCKeditor5Change = (i, newValue, innerIndex) => {
    let newContentData = cloneDeep(contentData);
    if (innerIndex !== undefined) newContentData[selectedLanguage][i].languages[innerIndex].blocks = newValue;
    else newContentData[selectedLanguage][i].languages.blocks = newValue;
    setContentData(newContentData);
  };

  const editPage = (id, isShared = false) => {
    setIsLoading(true);
    axios
      .post(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true&shared=" + (isShared ? "1" : "0"), {
        user: sessionStorage.getItem("vfiUser"),
        page: id,
      })
      .then((response) => {
        const data = response.data;
        let lang = "";
        for (var prop in data.languages) {
          if (prop === "en") {
            lang = prop;
          }
        }
        if (lang === "") {
          for (var prop in data.languages) {
            lang = prop;
            break;
          }
        }
        const state = { page_id: id };
        const title = data.languages[lang].title;
        if (!window.history.state) {
          window.history.pushState(state, title);
        } else if (window.history.state.page_id != id) {
          window.history.pushState(state, title);
        }

        data.screenSize = fullScreen;
        props.editPage(data, isShared);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const saveContent = useCallback(
    (editableAfterwards = false, keepListOpen = false) => {
      if (props.setConfirmBeforeNavigate) props.setConfirmBeforeNavigate("");

      setListIsOpen(listIsOpen && keepListOpen);

      if (!isEqual(contentData, contentBeforeEdit) && !saving) {
        saveButtonRef.current.setAttribute("disabled", true);
        setSaving(true);
        Axios.post(env.protocol + env.env + "/api/secured/pages/v2/SavePage", {
          pageLanguageId: data.languages[selectedLanguage].id,
          user: sessionStorage.getItem("vfiUser"),
          data: contentData,
        })
          .then((response) => {
            if (response.data.success === 1) {
              if (response.data.new_feature_image !== null) {
                const new_feature_image = response.data.new_feature_image.info;
                setFeatureImage({
                  id: new_feature_image.id,
                  extension: new_feature_image.file_extension,
                  file_name: new_feature_image.file_name,
                });
              }

              const newPageLanguage = response.data.new_page_language;
              if (newPageLanguage !== null) {
                let copyData = cloneDeep(data);
                copyData.languages[selectedLanguage] = newPageLanguage;
                for (let i = 0; i < copyData.history[selectedLanguage].length; i++) {
                  copyData.history[selectedLanguage][i].history = 1;
                }
                copyData.history[selectedLanguage].push(newPageLanguage);
                setData(copyData);
              }

              setContentBeforeEdit(cloneDeep(contentData));
            }
            if (!editableAfterwards) {
              setContentEditEnabled(false);
            }
            if (saveButtonRef.current) {
              saveButtonRef.current.removeAttribute("disabled");
            }
            setSaving(false);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (!editableAfterwards) {
          setContentEditEnabled(false);
        }
      }
    },
    [contentData, contentBeforeEdit, listIsOpen]
  );

  const changeStatus = (value, afterFunc) => {
    setLoading(true);

    Axios.post(env.protocol + env.env + "/api/secured/pages/ChangePageValue", {
      user: sessionStorage.getItem("vfiUser"),
      id: data.page.id,
      label: "current_status",
      value,
    })
      .then((response) => {
        let copyData = cloneDeep(data);
        copyData.page = response.data;
        setData(copyData);

        if (afterFunc) afterFunc(response);

        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const editFeatureImage = () => {
    // open image select
    setModuleIndexInEdit("featureMedia");
    props.setSiteRef(props.data.page.site_id);
    props.openOverlay("mediaChoose", {
      filter: { mediaType: ["images"] },
      singleMedia: true,
    });
  };

  const scheduleChange = (type, value) => {
    let dateCopy = new Date(data.page.date_to_publish);

    switch (type) {
      case "date":
        dateCopy.setFullYear(value.getFullYear());
        dateCopy.setMonth(value.getMonth());
        dateCopy.setDate(value.getDate());
        break;
      case "hours":
        dateCopy.setHours(value);
        break;
      case "minutes":
        dateCopy.setMinutes(value);
        break;
      default:
        break;
    }

    return (
      dateCopy.getFullYear() +
      "-" +
      moreZero(1 + dateCopy.getMonth()) +
      "-" +
      moreZero(dateCopy.getDate()) +
      " " +
      moreZero(dateCopy.getHours()) +
      ":" +
      moreZero(dateCopy.getMinutes()) +
      ":00"
    );
  };

  const dateChange = (type, value, afterFunc) => {
    setLoading(true);
    Axios.post(env.protocol + env.env + "/api/secured/pages/ChangePageValue", {
      label: "date_to_publish",
      value: scheduleChange(type, value),
      id: data.page.id,
      user: sessionStorage.getItem("vfiUser"),
    })
      .then((response) => {
        setLoading(false);
        if (afterFunc) afterFunc(response);
      })
      .catch((error) => console.error(error));
  };

  const generalText = languages[language].edit_page.option_section.general;

  const showSubGeneral = () => {
    switch (subGeneral) {
      case "location":
        return (
          <div className="general content">
            <div className="option-title go-back" onClick={() => setSubGeneral(null)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              {generalText.headline}
            </div>
            <div className="small-label title">{generalText.locations.toUpperCase()}</div>
            <div className={"locations-wrap" + (loading ? " loading" : "")}>
              {locations.map((e) => {
                return (
                  <div
                    key={e.id}
                    className={"location" + (data.locations.find((l) => e.id === l.id) ? " active" : "")}
                    onClick={() => {
                      setLoading(true);
                      Axios.post(env.protocol + env.env + "/api/secured/pages/ChangePageLocation", {
                        pageId: data.page.id,
                        locationId: e.id,
                      })
                        .then((response) => {
                          let copyData = cloneDeep(data);
                          copyData.locations = response.data;
                          setData(copyData);
                          setLoading(false);
                        })
                        .catch((error) => console.error(error));
                    }}
                  >
                    {e.name}
                  </div>
                );
              })}
            </div>
          </div>
        );
      case "status":
        return (
          <div className="general content">
            <div className="option-title go-back" onClick={() => setSubGeneral(null)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              {generalText.headline}
            </div>
            <div className="small-label title">
              {languages[language].edit_page.option_section.general.options.toUpperCase()}
            </div>

            <div className={"stats" + (loading ? " loading" : "")}>
              <label className="encapsulate stat">
                <div className="text">{generalText.draft}</div>
                <div className="small-label">{generalText.draft_info}</div>
                <input
                  className="radio"
                  type="radio"
                  checked={data.page.current_status === "draft"}
                  onChange={() => {
                    changeStatus("draft");
                  }}
                />
              </label>
              <label className="encapsulate stat">
                <div className="text">{generalText.hidden}</div>
                <div className="small-label">
                  {data.page.current_status !== "hidden"
                    ? generalText.hidden_info
                    : generalText.published_on + customDateDisplay(data.page.date_to_publish)}
                </div>
                <input
                  className="radio"
                  type="radio"
                  checked={data.page.current_status === "hidden"}
                  onChange={() => changeStatus("hidden")}
                />
              </label>
              <label className="encapsulate stat">
                <div className="text">{generalText.published}</div>
                <div className="small-label">
                  {data.page.current_status !== "published"
                    ? generalText.published_info
                    : generalText.published_on + customDateDisplay(data.page.date_to_publish)}
                </div>
                <input
                  className="radio"
                  type="radio"
                  checked={data.page.current_status === "published"}
                  onChange={() => {
                    changeStatus("published");
                  }}
                />
              </label>
              <label className="encapsulate stat">
                <div className="text">{generalText.scheduled}</div>
                <div className="small-label">{generalText.scheduled_info}</div>
                <input
                  className="radio"
                  type="radio"
                  checked={data.page.current_status === "scheduled"}
                  onChange={() => {
                    let copyData = cloneDeep(data);
                    copyData.page.current_status = "scheduled";
                    setData(copyData);
                  }}
                />
              </label>
              {data.page.current_status === "scheduled" ? (
                <div>
                  <div className="small-label" style={{ marginLeft: "5px", marginTop: "30px" }}>
                    {generalText.schedule.toUpperCase()}
                  </div>
                  <Calendar
                    className="custom-calendar"
                    value={new Date(data.page.date_to_publish)}
                    onChange={(e) =>
                      dateChange("date", e, (response) => {
                        let copyData = cloneDeep(data);
                        copyData.page.date_to_publish = response.data.date_to_publish;
                        setData(copyData);
                      })
                    }
                    minDate={new Date()}
                    formatShortWeekday={(locale, date) => {
                      const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
                      return days[date.getDay()];
                    }}
                  />
                  <div>{generalText.time}</div>
                  <Select
                    className="hours"
                    onChange={(e) =>
                      dateChange("hours", e.value, (response) => {
                        let copyData = cloneDeep(data);
                        copyData.page.date_to_publish = response.data.date_to_publish;
                        setData(copyData);
                      })
                    }
                    options={hours}
                    value={{
                      label: moreZero(new Date(data.page.date_to_publish).getHours()),
                    }}
                  />
                  :
                  <Select
                    className="minutes"
                    onChange={(e) =>
                      dateChange("minutes", e.value, (response) => {
                        let copyData = cloneDeep(data);
                        copyData.page.date_to_publish = response.data.date_to_publish;
                        setData(copyData);
                      })
                    }
                    options={minutes}
                    value={{
                      label: moreZero(new Date(data.page.date_to_publish).getMinutes()),
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      case "related-to":
        return (
          <div className="general content">
            <div className="option-title go-back" onClick={() => setSubGeneral(null)}>
              <FontAwesomeIcon icon={faChevronLeft} />
              {generalText.headline}
            </div>
            <div className="small-label title">
              {languages[language].edit_page.option_section.general.options.toUpperCase()}
            </div>

            <label className="encapsulate underline stat">
              <div className="text">Go to page</div>
              <div className="small-label">Go to the related page</div>
              <input
                className="radio transparent"
                type="radio"
                checked={false}
                onChange={() => {
                  navByLink("pages/id-" + data.article.related_page);
                  setPagesStore({ view_interact_type: "open" });
                }}
              />
            </label>
            <label className="encapsulate underline stat">
              <div className="text">Change related page</div>
              <div className="small-label">Change to another related page</div>
              <input
                className="radio transparent"
                type="radio"
                checked={false}
                onChange={() => {
                  setPagesStore({ view_interact_type: "relate_to" });
                  props.openOverlay("pagechoose", {
                    relateTo: true,
                    headline: "Select page to relate to",
                    disableBottomSelect: true,
                    afterFunc: (e) => {
                      changeArticleValue("related_page", e.id, (e) => {
                        let copyData = Object.assign({}, data);
                        copyData.article = copyData.article = {
                          ...data.article,
                          ...e.data.article,
                        };
                        setData(copyData);
                      });
                    },
                  });
                }}
              />
            </label>
            <label className="encapsulate underline stat">
              <div className="text">Delete related page</div>
              <div className="small-label">Delete the page from related articles.</div>
              <input
                className="radio transparent"
                type="radio"
                checked={false}
                onChange={() => {
                  changeArticleValue("related_page", null, (e) => {
                    setSubGeneral(null);
                    let copyData = Object.assign({}, data);
                    copyData.article = copyData.article = {
                      ...data.article,
                      ...e.data.article,
                    };
                    setData(copyData);
                  });
                }}
              />
            </label>
          </div>
        );
      default:
        return "Should not be shown :/";
    }
  };

  const onArticleAddressChange = useCallback(
    (e) => {
      changeArticleValue(
        ["address_name", "longitude", "latitude"],
        [e.formatted_address, e.geometry.location.lng(), e.geometry.location.lat()],
        (e) => {
          let copyData = Object.assign({}, data);
          copyData.article = {
            ...copyData.article,
            ...e.data.article,
          };
          setData(copyData);
        }
      );
    },
    [data]
  );

  switch (sideOption) {
    case "general":
      sideOptionContent =
        subGeneral === null ? (
          data.languages !== undefined ? (
            <div className={"general content " + (data.shared && "disabled")}>
              <div className="option-title">{generalText.headline}</div>
              {data.page.child_of === null && !isArticle ? (
                <div>
                  <div className="small-label title">{generalText.site_domain}</div>
                  <VfiInputText
                    key={data.site.protocol + data.site.domain_name}
                    defaultValue={data.site.protocol + data.site.domain_name}
                    disabled={true}
                  />
                  <div className="small-label">{generalText.site_domain_info}</div>
                </div>
              ) : (
                ""
              )}
              <div className="small-label title">
                {languages[language].edit_page.option_section.general.page_title.toUpperCase()} -{" "}
                {generalText.page_title_info}
              </div>
              <VfiInputText
                key={"title " + selectedLanguage}
                defaultValue={data.languages[selectedLanguage].title}
                onBlur={(e) => {
                  if (e.target.value !== data.languages[selectedLanguage].title)
                    changePageValue(
                      "title",
                      e.target.value,
                      (e) => {
                        let copyData = Object.assign({}, data);
                        copyData.languages[selectedLanguage].title = e.data.title;
                        copyData.languages[selectedLanguage].url_slug = e.data.url_slug;
                        setData(copyData);
                      },
                      selectedLanguage
                    );
                }}
                onChange={(e) => {}}
              />
              {data.page.child_of !== null ? (
                <>
                  <div className="small-label title">
                    {languages[language].edit_page.option_section.general.url_slug.toUpperCase()}
                  </div>
                  <VfiInputText
                    className="slug-input"
                    value={"/" + (newSlug ? newSlug : data.languages[selectedLanguage].url_slug)}
                    disabled={true}
                    customIcon={[
                      <GlobalToolTipController
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            data.site.protocol +
                              data.site.domain_name +
                              "/" +
                              (newSlug ? newSlug : data.languages[selectedLanguage].url_slug)
                          );
                        }}
                        click
                        closeDelay={3000}
                        solid
                        priority="left"
                        title=""
                        toolTipElements={<span>Link Copied!</span>}
                      >
                        <FontAwesomeIcon key={"copy"} icon={faLink} className="edit-slug-button" />
                      </GlobalToolTipController>,
                      <FontAwesomeIcon
                        key={"edit"}
                        icon={faPencil}
                        onClick={() => {
                          setEditSlug(true);
                        }}
                        className="edit-slug-button"
                      />,
                    ]}
                  />
                </>
              ) : (
                ""
              )}
              {newSlug && (
                <div className="small-label title">
                  Redirected: {data.languages[selectedLanguage].url_slug} {"->"} {newSlug}
                </div>
              )}
              <div className="small-label title">
                {languages[language].edit_page.option_section.general.description.toUpperCase()} -{" "}
                {generalText.description_info}
              </div>
              <VfiTextarea
                onBlur={(e) => {
                  changePageValue(
                    "description",
                    e,
                    (e) => {
                      let copyData = Object.assign({}, data);
                      copyData.languages[selectedLanguage].description = e.data.description;
                      setData(copyData);
                    },
                    selectedLanguage
                  );
                }}
                maxLength={160}
                defaultValue={data.languages[selectedLanguage].description}
                key={"description " + selectedLanguage}
              />
              <div className="option-title small-label image-title">
                {languages[language].edit_page.option_section.general.feature_image.toUpperCase()}
              </div>
              <div className="feature-image-container">
                <div className="image">
                  {featureImage.id !== null ? (
                    <img
                      src={
                        env.protocol +
                        env.env +
                        "/uploads/" +
                        featureImage.file_name +
                        "_medium." +
                        featureImage.extension
                      }
                      alt="temporary"
                    />
                  ) : (
                    <div className="no-image-wrap" onClick={editFeatureImage}>
                      <FontAwesomeIcon icon={faArrowToTop} />
                    </div>
                  )}
                </div>
                <div className="edit-image-container">
                  <div className="edit-image" onClick={editFeatureImage}>
                    {languages[language].edit_page.option_section.general.edit_or_change_image.toUpperCase()}
                  </div>
                  <div
                    className="delete-image"
                    onClick={() => {
                      Axios.post(env.protocol + env.env + "/api/secured/pages/ChangePageValue", {
                        user: sessionStorage.getItem("vfiUser"),
                        id: data.page.id,
                        label: "feature_media",
                        value: null,
                      })
                        .then((response) => {
                          let copyData = Object.assign({}, data);
                          copyData.page = response.data;
                          setData(copyData);

                          Axios.post(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true", {
                            page: data.page.id,
                          }).then((response) => {
                            setFeatureImage({
                              id: response.data.page.feature_media,
                              alt: response.data.page.feature_media_alt,
                              file_name: response.data.page.feature_media_file_name,
                              extension: response.data.page.feature_media_extension,
                            });
                          });
                        })
                        .catch((error) => console.error(error));
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                </div>
              </div>

              {!isArticle && (
                <div
                  className="encapsulate share"
                  onClick={() => {
                    props.openOverlay("pageLocation", {
                      page: data,
                      selectedLanguage,
                      preSelected: "websites",
                      displayList: ["websites"],
                    });
                  }}
                >
                  <div className="text">LOCATION</div>
                  <div className="small-label date"></div>
                </div>
              )}

              {isArticle && (
                <>
                  {/* Without data */}

                  {data.article.address_name === "" && (
                    <div
                      className="encapsulate location-on-map"
                      onClick={() => {
                        setSideOption("location-on-map");
                      }}
                    >
                      <div className="text">LOCATION ON MAP</div>
                    </div>
                  )}

                  <div
                    className="encapsulate infoscreens"
                    onClick={() => {
                      props.openOverlay("pageLocation", {
                        page: data,
                        selectedLanguage,
                        preSelected: "infoscreens",
                        displayList: ["infoscreens"],
                        afterFunc: (e) => {
                          setData({ ...e.page });
                        },
                      });
                    }}
                  >
                    <div className="text">INFOSCREENS</div>
                  </div>
                  {data.article.related_page ? (
                    <div className="encapsulate underline related-page" onClick={() => setSubGeneral("related-to")}>
                      <div className="small-label title">RELATED TO</div>
                      <div className="text">
                        {data.article.page?.languages?.[selectedLanguage]?.title ??
                          data.article.page?.languages?.en?.title ??
                          data.article.page?.languages?.[Object.keys(data.article.page?.languages)[0]]?.title}
                      </div>
                      <FontAwesomeIcon className="arrow" icon={faChevronRight} />
                    </div>
                  ) : (
                    <div
                      className="encapsulate relate-to-page"
                      onClick={() => {
                        setPagesStore({ view_interact_type: "relate_to" });
                        props.openOverlay("pagechoose", {
                          disableBottomSelect: true,
                          afterFunc: (e) => {
                            changeArticleValue("related_page", e.id, (e) => {
                              let copyData = Object.assign({}, data);
                              copyData.article = {
                                ...data.article,
                                ...e.data.article,
                              };
                              setData(copyData);
                            });
                          },
                        });
                      }}
                    >
                      <div className="text">RELATE TO PAGE</div>
                    </div>
                  )}

                  {/* With data */}

                  {data.article.address_name !== "" && (
                    <div
                      className={"encapsulate underline location"}
                      onClick={() => {
                        setSideOption("location-on-map");
                      }}
                    >
                      <div className="small-label title">PHYSICAL LOCATION</div>
                      <div className="text">{data.article.address_name}</div>
                      <FontAwesomeIcon className="arrow" icon={faChevronRight} />
                    </div>
                  )}
                </>
              )}

              <div className="encapsulate underline status" onClick={() => setSubGeneral("status")}>
                <div className="small-label title">
                  {languages[language].edit_page.option_section.general.status.toUpperCase()}
                </div>
                <div className="text">
                  {data.page.current_status === "draft"
                    ? generalText.draft
                    : data.page.current_status === "hidden"
                      ? generalText.hidden
                      : data.page.current_status === "published"
                        ? generalText.published
                        : data.page.current_status === "scheduled"
                          ? generalText.scheduled
                          : ""}
                </div>
                <div className="small-label date">
                  {data.page.date_to_publish ? customDateDisplay(data.page.date_to_publish) : ""}
                </div>
                <FontAwesomeIcon className="arrow" icon={faChevronRight} />
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          showSubGeneral()
        );
      break;
    case "seo":
      sideOptionContent = <div className="seo content">seo</div>;
      break;
    case "history":
      sideOptionContent = (
        <div className={"history content " + (data.shared && "disabled")}>
          <div className="option-title">{languages[language].edit_page.option_section.history.headline}</div>
          <PageHistory
            history={data.history}
            selectedLanguage={selectedLanguage}
            onClick={(e) => {
              setOptionsList(
                [
                  {
                    is: "choice",
                    icon: faEye,
                    text: "Preview",
                    onClick: () => {
                      const url =
                        data.site.protocol +
                        data.site.domain_name +
                        `/${selectedLanguage}/` +
                        data.languages[selectedLanguage].url_slug +
                        "?page_language_id_replace=" +
                        e.id;
                      window.open(url, "_blank");
                    },
                  },
                  {
                    is: "choice",
                    icon: faPencil,
                    text: "Set page to this state",
                    onClick: () => {
                      if (!contentEditEnabled || window.confirm("Warning! Unsaved data will be lost.")) {
                        axios
                          .post(env.protocol + env.env + "/api/secured/pages/SetHistoryPageLanguage", {
                            newPageLanguageId: e.id,
                            userId: sessionStorage.getItem("vfiUser"),
                          })
                          .then((response) => {
                            setData(response.data.page);
                            let copyContentData = cloneDeep(contentData);
                            copyContentData[selectedLanguage] = response.data.page.b_modules[selectedLanguage];
                            setContentData(copyContentData);
                            setContentEditEnabled(false);
                          })
                          .catch((error) => console.error(error));
                      }
                    },
                  },
                ],
                clickPosition(e, { x: 0, y: 0 })
              );
            }}
          />
        </div>
      );
      break;
    case "analytics":
      sideOptionContent = (
        <div className="analytics content" key="asdsdafsdfagfghd">
          <Analytics
            filters={`ga:pageTitle==${data.languages[selectedLanguage].title}`}
            viewId={data.site.viewId}
            charts={{
              geoChart: true,
              byDateChart: true,
              pageViewsPerPathChart: true,
            }}
          />
        </div>
      );
      break;
    case "location-on-map":
      sideOptionContent = (
        <div className={"general content " + (data.shared && "disabled")}>
          <div className="option-title go-back" onClick={() => setSideOption("general")}>
            <FontAwesomeIcon icon={faChevronLeft} />
            {generalText.headline}
          </div>
          {data.page.child_of === null ? (
            <div>
              <div className="small-label title">PHYSICAL LOCATION</div>
              <GoogleMapSearchBox
                key={data.article.address_name}
                defaultValue={data.article.address_name}
                change={onArticleAddressChange}
                onBlur={(e) => {
                  changeArticleValue("address_name", e.target.value, (e) => {
                    let copyData = Object.assign({}, data);
                    copyData.article = {
                      ...copyData.article,
                      ...e.data.article,
                    };
                    setData(copyData);
                  });
                }}
                name="address"
                placeholder={"Address"}
              />
              <div className="the-map-box">
                <ReactMapGlOpen
                  key={data.article.latitude + data.article.longitude}
                  mapStyle={"mapbox://styles/mapbox/light-v10"}
                  width="100%"
                  height="300px"
                  onClick={(e) => {
                    const {
                      lngLat: [lng, lat],
                    } = e;
                    getAddress(lat, lng).then((response) => {
                      const keys = ["longitude", "latitude"];
                      const values = [lng, lat];

                      if (response !== undefined) {
                        keys.push("address_name");
                        values.push(response);
                      }

                      changeArticleValue(keys, values, (e) => {
                        let copyData = cloneDeep(data);
                        copyData.article = { ...copyData.article, ...e.data.article };
                        setData(copyData);
                      });
                    });
                  }}
                  defaultViewport={{
                    ...defaultViewportValue,
                    latitude: parseFloat(data.article.latitude),
                    longitude: parseFloat(data.article.longitude),
                    zoom: data.article.address_name ? 12 : 8,
                  }}
                >
                  <Marker
                    longitude={parseFloat(data.article.longitude)}
                    latitude={parseFloat(data.article.latitude)}
                    offsetLeft={-18}
                    offsetTop={-50}
                  >
                    <img alt="Whatson content" src={mapMarkers["mapempty"]} />
                  </Marker>
                </ReactMapGlOpen>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
      break;
    default:
      sideOptionContent = "Should not get here...";
  }

  const mediaGridStartDrag = (moduleIndex, innerIndex) => {
    setModuleIndexInEdit(moduleIndex);
    setInnerIndex(innerIndex);
    setIsDragging(true);
  };

  const mediaGridDrop = (moduleIndex2nd, innerIndex2nd) => {
    // Do the swap.
    if (
      contentData[selectedLanguage][moduleIndexInEdit].type === "media_grid" &&
      contentData[selectedLanguage][moduleIndex2nd].type === "media_grid"
    ) {
      const keyMain = contentData[selectedLanguage][moduleIndexInEdit].media[innerIndex].key;
      const key2nd = contentData[selectedLanguage][moduleIndex2nd].media[innerIndex2nd].key;

      let copyContent = cloneDeep(contentData);
      // Store temp...
      let copyContentLang = copyContent[selectedLanguage];
      const tempStoreM = copyContentLang[moduleIndex2nd].media[innerIndex2nd];
      let tempStoreL = {};
      tempStoreL = copyContentLang[moduleIndex2nd].languages[innerIndex2nd];

      // Set where temp was...
      copyContentLang[moduleIndex2nd].media[innerIndex2nd] = copyContentLang[moduleIndexInEdit].media[innerIndex];
      copyContentLang[moduleIndex2nd].media[innerIndex2nd].key = key2nd;
      copyContentLang[moduleIndex2nd].languages[innerIndex2nd] =
        copyContentLang[moduleIndexInEdit].languages[innerIndex];

      // Set temp to other...
      copyContentLang[moduleIndexInEdit].media[innerIndex] = tempStoreM;
      copyContentLang[moduleIndexInEdit].media[innerIndex].key = keyMain;
      copyContentLang[moduleIndexInEdit].languages[innerIndex] = tempStoreL;

      setContentData(copyContent);
      setIsDragging(false);
    }
  };

  const whatsonGridDrop = (moduleIndex2nd, innerIndex2nd) => {
    // Do the swap.
    if (
      contentData[selectedLanguage][moduleIndexInEdit].type === "whatson_grid" &&
      contentData[selectedLanguage][moduleIndex2nd].type === "whatson_grid"
    ) {
      const keyMain = contentData[selectedLanguage][moduleIndexInEdit].whatson_list[innerIndex].key;
      const key2nd = contentData[selectedLanguage][moduleIndex2nd].whatson_list[innerIndex2nd].key;

      let copyContent = cloneDeep(contentData);
      // Store temp...
      let copyContentLang = copyContent[selectedLanguage];
      const tempStoreM = copyContentLang[moduleIndex2nd].whatson_list[innerIndex2nd];

      // Set where temp was...
      copyContentLang[moduleIndex2nd].whatson_list[innerIndex2nd] =
        copyContentLang[moduleIndexInEdit].whatson_list[innerIndex];
      copyContentLang[moduleIndex2nd].whatson_list[innerIndex2nd].key = key2nd;

      // Set temp to other...
      copyContentLang[moduleIndexInEdit].whatson_list[innerIndex] = tempStoreM;
      copyContentLang[moduleIndexInEdit].whatson_list[innerIndex].key = keyMain;

      setContentData(copyContent);
      setIsDragging(false);
    }
  };

  const DefaultModuleSettingsButtons = (index) => {
    const [innerValues, setInnerValues] = useState(contentData[selectedLanguage][index.index]);
    useEffect(() => {
      let copyData = cloneDeep(contentData);
      copyData[selectedLanguage][index.index] = innerValues;
      setContentData(copyData);
    }, [innerValues]);
    return (
      <div className="edit-module">
        <button
          className="exit-btn"
          onClick={() => {
            setListIsOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>

        <div className="top"></div>
        <div className="content">
          <label className="option background-color">
            <div className="label">Background Color</div>
            <div className="value">
              <div
                className={(innerValues.backgroundColor === "0" ? "selected" : "") + " color1"}
                onClick={() => {
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.backgroundColor = "0";
                  setInnerValues(copyInnerValues);
                }}
              ></div>
              <div
                className={(innerValues.backgroundColor === "1" ? "selected" : "") + " color2"}
                onClick={() => {
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.backgroundColor = "1";
                  setInnerValues(copyInnerValues);
                }}
              ></div>
              <div
                className={(innerValues.backgroundColor === "2" ? "selected" : "") + " color3"}
                onClick={() => {
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.backgroundColor = "2";
                  setInnerValues(copyInnerValues);
                }}
              ></div>
              <div
                className={(innerValues.backgroundColor === "3" ? "selected" : "") + " color4"}
                onClick={() => {
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.backgroundColor = "3";
                  setInnerValues(copyInnerValues);
                }}
              ></div>
            </div>
          </label>
          <label className="option margin-top">
            <div className="label">Margin Top</div>
            <div className="value">
              <input
                defaultValue={innerValues.marginTop ? innerValues.marginTop : 0}
                onChange={(e) => {
                  e.target.value = e.target.value === "" ? "0" : e.target.value;
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.marginTop = e.target.value;
                  setInnerValues(copyInnerValues);
                }}
                type="number"
              ></input>
              <span>px</span>
            </div>
          </label>
          <label className="option margin-bottom">
            <div className="label">Margin Bottom</div>
            <div className="value">
              <input
                type={"number"}
                defaultValue={innerValues.marginBottom ? innerValues.marginBottom : 0}
                onChange={(e) => {
                  e.target.value = e.target.value === "" ? "0" : e.target.value;
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.marginBottom = e.target.value;
                  setInnerValues(copyInnerValues);
                }}
              ></input>
              <span>px</span>
            </div>
          </label>
          {innerValues.type === "ckeditor_5" && (
            <label className="option margin-left">
              <div className="label">Margin Left</div>
              <div className="value">
                <input
                  type={"number"}
                  defaultValue={innerValues.marginLeft ? innerValues.marginLeft : 0}
                  onChange={(e) => {
                    e.target.value = e.target.value === "" ? "0" : e.target.value;
                    let copyInnerValues = cloneDeep(innerValues);
                    copyInnerValues.marginLeft = e.target.value;
                    setInnerValues(copyInnerValues);
                  }}
                ></input>
                <span>px</span>
              </div>
            </label>
          )}
          <label className="option padding-top">
            <div className="label">Padding Top</div>
            <div className="value">
              <input
                defaultValue={innerValues.paddingTop ? innerValues.paddingTop : 0}
                onChange={(e) => {
                  e.target.value = e.target.value === "" ? "0" : e.target.value;
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.paddingTop = e.target.value;
                  setInnerValues(copyInnerValues);
                }}
                type="number"
              ></input>
              <span>px</span>
            </div>
          </label>
          <label className="option padding-bottom">
            <div className="label">Padding Bottom</div>
            <div className="value">
              <input
                defaultValue={innerValues.paddingBottom ? innerValues.paddingBottom : 0}
                onChange={(e) => {
                  e.target.value = e.target.value === "" ? "0" : e.target.value;
                  let copyInnerValues = cloneDeep(innerValues);
                  copyInnerValues.paddingBottom = e.target.value;
                  setInnerValues(copyInnerValues);
                }}
                type="number"
              ></input>
              <span>px</span>
            </div>
          </label>
        </div>
      </div>
    );
  };

  const ModuleSettingsButtons = (index) => {
    return (
      <div
        className="settings module-option"
        onClick={(e) => {
          setOptionsList(
            <DefaultModuleSettingsButtons index={index.index} />,
            clickPosition(e, { x: 20, y: 0 }),
            true,
            closeWhenOutClick === false ? false : true
          );
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
    );
  };
  const defaultOffset = { x: 0, y: 0 };
  const DefaultModuleButtons = ({ index, offset = defaultOffset, customOptions, closeWhenOutClick, extraOptions }) => {
    return (
      <div className="default-module-buttons" key="default-module-buttons">
        <GlobalToolTipController
          offset={{ x: 15, y: 30 }}
          title=""
          delay={600}
          toolTipElements={<div>Add new module</div>}
          className="add-module-above"
          onClick={(e) => addModuleBetween(e, index, RenderPageModules)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </GlobalToolTipController>
        <GlobalToolTipController
          offset={{ x: 15, y: 30 }}
          title=""
          delay={600}
          toolTipElements={<div>Add new module</div>}
          className="add-module-below"
          onClick={(e) => addModuleBetween(e, index + 1, RenderPageModules)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </GlobalToolTipController>

        <div
          className="module-options-2"
          style={{
            transform: offset ? `translate(${offset.x}px,${offset.y}px)` : "unset",
          }}
        >
          <div
            className={"module-option" + (customOptions ? "" : " inactive")}
            onClick={(e) => {
              const CustomOptions = customOptions;
              if (customOptions)
                setOptionsList(
                  <CustomOptions
                    key={index}
                    setIsLoading={setIsLoading}
                    values={contentData[selectedLanguage][index]}
                    change={(e) => {
                      let copyData = cloneDeep(contentData);
                      copyData[selectedLanguage][index] = e;
                      setContentData(copyData);
                    }}
                    openOverlay={(what, data) => {
                      props.setSiteRef(props.data.page.site_id);
                      setModuleIndexInEdit(index);
                      props.openOverlay(what, data);
                    }}
                    setModuleKeyInEdit={setModuleKeyInEdit}
                    siteId={props.data.page.site_id}
                    chosenLanguages={chosenLanguages}
                    close={() => setListIsOpen(false)}
                    isArticle={isArticle}
                    selectedLanguage={selectedLanguage}
                  />,
                  clickPosition(e, { x: 20, y: 0 }),
                  true,
                  closeWhenOutClick === false ? false : true
                );
            }}
          >
            <FontAwesomeIcon icon={faPen} />
          </div>
          {extraOptions
            ? extraOptions(index, contentData, setContentData).map((element) => {
                return (
                  <div key={element.text} className="module-option" style={element.style} onClick={element.onClick}>
                    <FontAwesomeIcon icon={element.icon} />
                  </div>
                );
              })
            : ""}
          {defaultModuleOptions(index).map((element) => {
            if (element.appear) {
              if (element.appear() === false) {
                return "";
              }
            }

            return (
              <div key={element.text} className="module-option" style={element.style} onClick={element.onClick}>
                <FontAwesomeIcon icon={element.icon} />
              </div>
            );
          })}
          <ModuleSettingsButtons index={index} />
        </div>
      </div>
    );
  };

  return (
    <div className="edit-page row">
      {editSlug && (
        <ChangeURLSlugModal
          setEditSlug={setEditSlug}
          slug={data.languages[selectedLanguage].url_slug}
          setNewSlug={setNewSlug}
          pageLanguageID={data.languages[selectedLanguage].id}
        />
      )}
      <OptionsList
        isOpen={listIsOpen}
        close={() => setListIsOpen(false)}
        options={optionsListOptions}
        position={optionsListPos}
        customElements={customElements}
        deep={closeWhenOutClick ? undefined : 1}
        backgroundBlur={optionsListBGBlur}
      />
      {contentEditEnabled ? <MyUnloadComponent /> : ""}
      <div className="page-section col-lg-9">
        <div className="page-section-header">
          <div className="fixed">
            <span
              className="header-title"
              onClick={() => {
                if (contentEditEnabled) {
                  if (window.confirm(languages[language].edit_page.re_navigate_warning)) props.close();
                } else {
                  props.close();
                }

                if (route.length > 1) back();
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <div className="title-text">
                {props.goBackText ??
                  (isArticle
                    ? languages[language].edit_page.header.articles
                    : props.history === "appearances"
                      ? languages[language].edit_page.header.appearances
                      : languages[language].edit_page.header.pages)}
              </div>
            </span>
            {chosenLanguages
              .sort((a, b) => {
                if (props.data.languages[a] && props.data.languages[b])
                  return props.data.languages[a].order_by > props.data.languages[b].order_by;
              })
              .map((e, i) => (
                <div
                  className={
                    "one-language" +
                    (selectedLanguage === e ? " selected" : "") +
                    (contentEditEnabled || isLoading ? " disabled" : "") +
                    (chosenLanguages.length === 1 ? " single" : "")
                  }
                  key={i}
                >
                  <div className="one-language-wrap">
                    <div
                      className="the-language"
                      onClick={() => {
                        setSelectedLanguage(e);
                        let c = cloneDeep(contentData);
                        setContentData([]);
                        setTimeout(() => {
                          setContentData(c);
                        }, 0);
                      }}
                    >
                      {e.toUpperCase()}
                    </div>
                    {chosenLanguages.length > 1 && !data.shared && (
                      <div
                        className="trash"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to remove " +
                                e.toUpperCase() +
                                " from language list? This action cannot be undone."
                            )
                          ) {
                            setIsLoading(true);
                            axios
                              .post(env.protocol + env.env + "/api/secured/pages/DeletePageLanguage", {
                                id: data.page.id,
                                language: e,
                              })
                              .then((response) => {
                                const lang = Object.keys(response.data.languages).reduce(
                                  (previousValue, currentValue) => {
                                    if (previousValue !== undefined) {
                                      if (
                                        response.data.languages[previousValue].order_by <
                                        response.data.languages[currentValue].order_by
                                      ) {
                                        return previousValue;
                                      } else {
                                        return currentValue;
                                      }
                                    } else {
                                      return currentValue;
                                    }
                                  },
                                  undefined
                                );
                                setSelectedLanguage(lang);
                                setChosenLanguages(Object.keys(response.data.languages));
                                setContentData(response.data.b_modules);
                                setIsLoading(false);
                              })
                              .catch((error) => console.error(error));
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {!data.shared && (
              <GlobalToolTipController
                offset={{ x: 10, y: 30 }}
                priority={"right,bottom"}
                title=""
                delay={600}
                toolTipElements={<div>Add language</div>}
                className={"add-language" + (contentEditEnabled ? " disabled" : "")}
                onClick={(e) => {
                  setOptionsList(addLanguageChoices, clickPosition(e));
                }}
              >
                +
              </GlobalToolTipController>
            )}
          </div>
        </div>

        {data.languages && Object.keys(contentData).length > 0 ? (
          <div
            className={
              "page-section-content " +
              (fullScreen === "full_screen" ? "fullscreen " : "") +
              (fullScreen === "custom" ? "custom " : "") +
              (boxWidth <= 1440 && boxWidth > 112 ? "smallerDesktop " : "") +
              (boxWidth <= 1230 && boxWidth > 1024 ? "size1200 " : "") +
              (boxWidth <= 1024 && boxWidth > 880 ? "tablet " : "") +
              (boxWidth <= 880 ? "phone" : "") +
              (boxWidth <= 730 && boxWidth > 380 ? " size700" : "") +
              (boxWidth <= 380 ? " size350" : "") +
              (boxWidth <= 2019 ? " xxxl" : "") +
              (boxWidth <= 1535 ? " xxl" : "") +
              (boxWidth <= 1279 ? " xl" : "") +
              (boxWidth <= 1279 ? " lg" : "") +
              (boxWidth <= 767 ? " md" : "") +
              (boxWidth <= 639 ? " sm" : "")
            }
            style={
              !isArticle
                ? {
                    width: boxWidth + "px",
                    height: parseInt(boxHeight) + 50 + "px",
                  }
                : {}
            }
          >
            {isLoading ? (
              <div className="blur">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            ) : (
              ""
            )}
            <div className="page-content-top">
              {contentEditEnabled ? (
                <div className="edit-section">
                  <button
                    ref={saveButtonRef}
                    className={"save-button" + (saving ? " disabled" : "")}
                    onClick={() => {
                      saveContent();
                    }}
                  >
                    <GlobalToolTipController
                      offset={{ x: 10, y: 30 }}
                      priority={"right,bottom"}
                      title=""
                      delay={600}
                      toolTipElements={<div>Save page content</div>}
                    >
                      {languages[language].edit_page.page_content.save.toUpperCase()}
                    </GlobalToolTipController>
                  </button>
                  <GlobalToolTipController
                    offset={{ x: 10, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Discard your changes</div>}
                    className="discard-button"
                    onClick={() => {
                      setContentEditEnabled(false);
                      props.setConfirmBeforeNavigate("");
                      let oldContent = cloneDeep(contentBeforeEdit);
                      setContentData([]);
                      setTimeout(() => {
                        setContentData(oldContent);
                      }, 0);
                    }}
                  >
                    {languages[language].edit_page.page_content.discard_changes.toUpperCase()}
                  </GlobalToolTipController>
                  <GlobalToolTipController
                    offset={{ x: 10, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Save this page's layout as a template</div>}
                    className="save-template-button"
                    onClick={(e) => {
                      setOptionsList(
                        <SaveTemplate content={contentData[selectedLanguage]} />,
                        { x: e.pageX, y: e.pageY },
                        true
                      );
                    }}
                  >
                    {languages[language].edit_page.page_content.save_template.toUpperCase()}
                  </GlobalToolTipController>
                </div>
              ) : !data.shared ? (
                <div className="edit-section">
                  <GlobalToolTipController
                    offset={{ x: 10, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Edit page content</div>}
                    className="edit-button"
                    onClick={() => {
                      setContentEditEnabled(true);
                      props.setConfirmBeforeNavigate(languages[language].edit_page.re_navigate_warning);
                      setContentBeforeEdit(cloneDeep(contentData));
                    }}
                  >
                    {languages[language].edit_page.page_content.edit.toUpperCase()}
                  </GlobalToolTipController>
                </div>
              ) : (
                <div></div>
              )}
              <div className="headline-text">
                {!data.shared ? (
                  data.languages[selectedLanguage].title
                ) : (
                  <>
                    <b>Shared by:</b> <i>{data.site.name}</i>
                  </>
                )}
              </div>
              {!isArticle && (
                <>
                  <ToolTip
                    offset={{ x: 30, y: 30 }}
                    priority={"right,bottom"}
                    title=""
                    delay={600}
                    toolTipElements={<div>Show in live format</div>}
                    className={"device-display-button text " + (contentEditEnabled ? "disabled " : "")}
                    onClick={() => {
                      window.open(
                        data.domain + "/" + selectedLanguage + "/" + data.languages[selectedLanguage].url_slug,
                        "_blank"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faExternalLink} />
                  </ToolTip>
                  <div className="wrap-device-buttons">
                    <ToolTip
                      offset={{ x: 30, y: 30 }}
                      priority={"right,bottom"}
                      title=""
                      delay={600}
                      toolTipElements={<div>Display in normal view</div>}
                      className={"device-display-button " + (fullScreen === false ? "active " : "")}
                      onClick={() => {
                        setFullScreen(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faDesktop} />
                    </ToolTip>
                    <ToolTip
                      offset={{ x: 30, y: 30 }}
                      priority={"right,bottom"}
                      title=""
                      delay={600}
                      toolTipElements={<div>Display in tablet view</div>}
                      className={"device-display-button " + (fullScreen === "tablet" ? "active" : "")}
                      onClick={() => {
                        setFullScreen(fullScreen !== "tablet" ? "tablet" : false);
                        setBoxWidth("1024");
                        setBoxHeight("768");
                      }}
                    >
                      <FontAwesomeIcon icon={faTablet} />
                    </ToolTip>
                    <ToolTip
                      offset={{ x: 30, y: 30 }}
                      priority={"right,bottom"}
                      title=""
                      delay={600}
                      toolTipElements={<div>Display in phone view</div>}
                      className={"device-display-button " + (fullScreen === "phone" ? "active " : "")}
                      onClick={() => {
                        setFullScreen(fullScreen !== "phone" ? "phone" : false);
                        setBoxWidth("375");
                        setBoxHeight("667");
                      }}
                    >
                      <FontAwesomeIcon icon={faMobile} />
                    </ToolTip>
                  </div>
                  {boxWidth !== undefined || boxHeight !== undefined ? (
                    <div className="scale-wrap">
                      x:{" "}
                      <VfiInputText
                        className="display-scale"
                        inputType="int"
                        value={boxWidth}
                        onChange={(e) => {
                          setBoxWidth(e.target.value);
                        }}
                      />
                      y:{" "}
                      <VfiInputText
                        className="display-scale"
                        inputType="int"
                        value={boxHeight}
                        onChange={(e) => {
                          setBoxHeight(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
              <ToolTip
                offset={{ x: 30, y: 30 }}
                priority={"right,bottom"}
                title=""
                delay={600}
                toolTipElements={<div>Display full screen</div>}
                className={"expand-button " + (fullScreen === "full_screen" ? "active " : "")}
                onClick={() => {
                  setFullScreen(fullScreen !== "full_screen" ? "full_screen" : false);
                }}
              >
                <FontAwesomeIcon icon={faExpandAlt} />
              </ToolTip>
            </div>
            <div className={"page-content" + (contentEditEnabled ? "" : " disabled")} ref={pageContentRef}>
              {!isArticle ? (
                <div
                  className={
                    "backgroundColor" +
                    (contentData[selectedLanguage] && contentData[selectedLanguage][0]
                      ? contentData[selectedLanguage][0].backgroundColor
                      : "") +
                    " wrap-header-menu" +
                    (data.shared ? " hidden" : "")
                  }
                >
                  <div
                    style={{
                      display: fullScreen === "tablet" || fullScreen === "phone" ? "none" : "flex",
                    }}
                    className={"header-menu" + (pageContentFromTop > 0 ? " not-top" : "")}
                  >
                    <div
                      onClick={() => {
                        editPage(props.data.rootPageId ?? props.data.site.page.id);
                      }}
                      className="logo"
                    >
                      {!env.env.includes("vita.fo") && (
                        <div className="sheephead">
                          <img src={mainLogo} alt="sheep" />
                        </div>
                      )}
                      <div className="text font-face-gm">{siteName}</div>
                    </div>
                    <div className="top-menu">
                      {menues.map((element, i) => {
                        return (
                          <div key={element.id + " " + i} className="eachMenu">
                            <div
                              className="hoverMenu"
                              onClick={() => {
                                editPage(element.id, !!element.shared);
                              }}
                              key={i}
                            >
                              {element.pl_title}
                            </div>
                            {element.children.length > 0 && (
                              <div className="wrap">
                                <div className="indicator"></div>
                                <div className="dropdownMenu">
                                  <div className="second-level">
                                    {element.children.map((secondElment, j) => {
                                      return (
                                        <div
                                          className="each-second-level"
                                          key={`second-element-${j}-${secondElment.id}`}
                                        >
                                          <span
                                            onClick={() => {
                                              editPage(secondElment.id, !!secondElment.shared);
                                            }}
                                          >
                                            {secondElment.pl_title}
                                          </span>
                                          <div className="third-level">
                                            {secondElment.children.map((thirdElment, k) => {
                                              return (
                                                <span
                                                  onClick={() => {
                                                    editPage(thirdElment.id, !!thirdElment.shared);
                                                  }}
                                                  key={`third-level-${k}-${thirdElment.id}-${
                                                    thirdElment.shared ? "shared" : ""
                                                  }`}
                                                >
                                                  <div className="icon">
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                  </div>
                                                  <div key={k}>{thirdElment.pl_title}</div>
                                                </span>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {contentData[selectedLanguage]
                ? contentData[selectedLanguage].map((element, i) => {
                    const ckToolbar = isArticle ? defaultCkToolbar.filter((e) => e !== "link") : undefined;

                    if (element.type === "location") {
                      return (
                        <div className={" module location"} key={element.key}>
                          <div
                            key={element.type + i}
                            style={{
                              marginTop: element.marginTop + "px",
                              marginBottom: element.marginBottom + "px",

                              paddingBottom: element.paddingBottom + "px",
                              paddingTop: element.paddingTop + "px",
                            }}
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <Location
                              data={element}
                              onChange={(e) => {
                                let copyContent = cloneDeep(contentData);
                                copyContent[selectedLanguage][i] = e;
                                setContentData(copyContent);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "ckeditor_5") {
                      if (element.new || element.default) {
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" row module ckeditor_5-text"} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <VfiCKeditor
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              marginLeft={element.marginLeft}
                              className="vfi-ckeditor"
                              value={element.languages.blocks}
                              onBlur={(e) => {
                                handleCKeditor5Change(i, e);
                              }}
                              toolbar={ckToolbar}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "ckeditor_5_excerpt") {
                      return (
                        <div className={" module"} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons
                              index={i}
                              extraOptions={() =>
                                CKEditorExcerptMO(i, contentData[selectedLanguage], (newData) => {
                                  let copyContent = cloneDeep(contentData);
                                  copyContent[selectedLanguage] = newData;
                                  setContentData(copyContent);
                                })
                              }
                            />
                            <CKEditorExcerpt
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              element={element}
                              onBlur={(e) => handleCKeditor5Change(i, e)}
                              selectedLanguage={selectedLanguage}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "text_and_expand") {
                      return (
                        <div className={" text-and-expan module"} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons
                              index={i}
                              extraOptions={() =>
                                TextAndExpandMO(i, contentData[selectedLanguage], (newContentDataLang) => {
                                  setContentData({
                                    ...contentData,
                                    [selectedLanguage]: newContentDataLang,
                                  });
                                })
                              }
                              swapOption={true}
                            />
                            <TextAndExpand
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              className=""
                              value1={element.languages[0] ? element.languages[0].blocks : ""}
                              value2={element.languages[0] ? element.languages[1].blocks : ""}
                              onBlur={(e, j) => {
                                handleCKeditor5Change(i, e, j);
                              }}
                              data={element}
                              toolbar={ckToolbar}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "related_and_text") {
                      return (
                        <div className={" module"} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <RelatedAndText
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={element}
                              changeFeature={() => {
                                setModuleIndexInEdit(i);
                                props.openOverlay("pagechoose", {
                                  disableBottomSelect: true,
                                });
                              }}
                              value={element.languages.blocks}
                              onBlur={(e) => {
                                handleCKeditor5Change(i, e);
                              }}
                              inEdit={contentEditEnabled}
                              editPage={editPage}
                              // toolbar={editPageCkToolbar}
                              selectedLanguage={selectedLanguage}
                            />
                          </div>
                        </div>
                      );
                    }
                    if (element.type === "media") {
                      if (element.new || element.default) {
                        element.paddingBottom = 25;
                        element.paddingTop = 25;
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div
                          className={" module a-media" + (element.size === "full" ? " disable-maxwidth" : "")}
                          key={element.key}
                        >
                          <div
                            style={{
                              marginTop: element.marginTop + "px",
                              marginBottom: element.marginBottom + "px",
                              paddingBottom: element.paddingBottom + "px",
                              paddingTop: element.paddingTop + "px",
                            }}
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} customOptions={OneMediaMO} />
                            {!element.isVideoUrl ? (
                              <OneMedia
                                siteRef={props.data.page.site_id}
                                setSiteRef={props.setSiteRef}
                                openOverlay={props.openOverlay}
                                whatMedia={["images", "videos"]}
                                media={element.media}
                                size={element.size}
                                onClick={() => {
                                  setModuleIndexInEdit(i);
                                }}
                              />
                            ) : (
                              <div className={"mediaDiv " + element.size}>
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    key={element.videoUpdate}
                                    className={"react-player"}
                                    url={
                                      // "https://player.vimeo.com/video/" +
                                      element.videoUrl
                                    }
                                    config={
                                      element.videoUrlParams
                                        ? {
                                            youtube: {
                                              playerVars: {
                                                autoplay: element.videoUrlParams.autoplay ? 1 : 0,
                                                controls: element.videoUrlParams.controls ? 1 : 0,
                                                start: element.videoUrlParams.start,
                                                end: element.videoUrlParams.end,
                                                loop: element.videoUrlParams.loop ? 1 : 0,
                                                playlist: element.videoUrlParams.playlist,
                                              },
                                            },
                                            vimeo: {
                                              playerOptions: {
                                                autoplay: element.videoUrlParams.autoplay ? 1 : 0,
                                                controls: element.videoUrlParams.controls ? 1 : 0,
                                                loop: element.videoUrlParams.loop ? true : false,
                                              },
                                            },
                                            soundcloud: {
                                              options: {
                                                auto_play: element.videoUrlParams.autoplay ? 1 : 0,
                                              },
                                            },
                                          }
                                        : {}
                                    }
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            )}
                            {element.caption === "under_image" && (
                              <VfiCKeditor
                                placeholder="Insert text..."
                                className={"caption " + element.size}
                                value={element.captionText}
                                onBlur={(e) => {
                                  let copyContentData = cloneDeep(contentData);
                                  copyContentData[selectedLanguage][i] = {
                                    ...copyContentData[selectedLanguage][i],
                                    captionText: e,
                                  };
                                  setContentData(copyContentData);
                                }}
                                toolbar={ckToolbar}
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                    if (element.type === "newsletter") {
                      if (element.new || element.default) {
                        element.paddingBottom = 65;
                        element.paddingTop = 75;
                        element.backgroundColor = "0";
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" module a-media "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <div>
                              <DefaultModuleButtons index={i} customOptions={NewsletterMO} />
                              <Newsletter
                                key={contentEditEnabled}
                                style={{
                                  marginTop: element.marginTop + "px",
                                  marginBottom: element.marginBottom + "px",
                                  paddingBottom: element.paddingBottom + "px",
                                  paddingTop: element.paddingTop + "px",
                                }}
                                language={selectedLanguage}
                                data={element}
                                onChange={(e) => {
                                  let copyContent = cloneDeep(contentData);
                                  copyContent[selectedLanguage][i] = e;
                                  setContentData(copyContent);
                                }}
                                optionsListAppear={(bool) => {
                                  setOptionsList(
                                    <NewsletterMO
                                      values={contentData[selectedLanguage][i]}
                                      change={(e) => {
                                        let copyData = cloneDeep(contentData);
                                        copyData[selectedLanguage][i] = e;
                                        setContentData(copyData);
                                      }}
                                    />,
                                    optionsListPos,
                                    true,
                                    !bool,
                                    bool && contentEditEnabled,
                                    bool
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                    if (element.type === "media_with_subtext_and_text") {
                      return (
                        <div
                          className={
                            (element.backgroundColor && "backgroundColor" + element.backgroundColor) +
                            " wrap-module" +
                            " module a-media"
                          }
                          key={element.key}
                        >
                          <DefaultModuleButtons
                            index={i}
                            extraOptions={() =>
                              MediaWithSubtextAndTextMO(i, contentData[selectedLanguage], (newContentDataLang) => {
                                setContentData({
                                  ...contentData,
                                  [selectedLanguage]: newContentDataLang,
                                });
                              })
                            }
                            swapOption={true}
                          />
                          <MediaWithSubtextAndText
                            style={{
                              marginTop: element.marginTop + "px",
                              marginBottom: element.marginBottom + "px",
                              paddingBottom: element.paddingBottom + "px",
                              paddingTop: element.paddingTop + "px",
                            }}
                            siteRef={props.data.page.site_id}
                            setSiteRef={props.setSiteRef}
                            openOverlay={props.openOverlay}
                            media={element.media}
                            onClickMedia={() => {
                              setModuleIndexInEdit(i);
                            }}
                            defaultSubText={element.languages[0].blocks}
                            onBlurSubText={(e) => {
                              handleCKeditor5Change(i, e, 0);
                            }}
                            rightTextValue={element.languages[1].blocks}
                            onRightTextBlur={(e) => {
                              handleCKeditor5Change(i, e, 1);
                            }}
                            data={element}
                            toolbar={ckToolbar}
                          />
                        </div>
                      );
                    }

                    if (element.type === "read_also") {
                      if (element.new || element.default) {
                        element.paddingBottom = 25;
                        element.paddingTop = 25;
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" module"} key={element.key}>
                          <div
                            style={{
                              marginTop: element.marginTop + "px",
                              marginBottom: element.marginBottom + "px",
                              paddingBottom: element.paddingBottom + "px",
                              paddingTop: element.paddingTop + "px",
                            }}
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <ReadAlso
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={element}
                              onClick={() => {
                                if (contentEditEnabled) {
                                  setModuleIndexInEdit(i);
                                  props.openOverlay("pagechoose", {
                                    disableBottomSelect: true,
                                  });
                                } else {
                                  editPage(element.pageId);
                                }
                              }}
                              selectedLanguage={selectedLanguage}
                              inEdit={contentEditEnabled}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "carousel") {
                      if (element.new || element.default) {
                        element.paddingBottom = 25;
                        element.paddingTop = 25;
                        element.new = false;
                        element.default = false;
                        if (i === 0) {
                          //If top module, not padding should be added.
                          element.paddingTop = 25;
                        }
                      }
                      let firstCarousel = "";

                      if (contentData[selectedLanguage][0]) {
                        if (contentData[selectedLanguage][0].type === "carousel") {
                          firstCarousel = "firstCarousel";
                        }
                      }
                      let mediaToComponent = [];
                      element.media.forEach((e, j) => {
                        mediaToComponent.push({
                          ...e,
                          ...contentData[selectedLanguage][i].languages[j],
                        });
                      });
                      return (
                        <div className={" module disable-maxwidth carousel " + firstCarousel} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} customOptions={VfiCarouselMO} />
                            <VfiCarousel
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              siteRef={props.data.page.site_id}
                              setSiteRef={props.setSiteRef}
                              openOverlay={(whatOverlay, data, innerIndex, key) => {
                                setModuleIndexInEdit(i);
                                setInnerIndex(innerIndex);
                                setModuleKeyInEdit(key);
                                props.setSiteRef(props.data.page.site_id);
                                props.openOverlay(whatOverlay, data);
                              }}
                              whatMedia={["images", "videos"]}
                              data={element}
                              is_hero={element.is_hero}
                              media={mediaToComponent}
                              galleryType={element.galleryType}
                              addMedia={() => {
                                setModuleKeyInEdit("media_add");
                                setModuleIndexInEdit(i);
                                props.setSiteRef(props.data.page.site_id);
                                props.openOverlay("mediaChoose", {
                                  filter: { mediaType: ["images", "videos"] },
                                  singleMedia: false,
                                });
                              }}
                              choosePage={(innerIndex) => {
                                setInnerIndex(innerIndex);
                                setModuleKeyInEdit("page_change");
                                setModuleIndexInEdit(i);
                                props.openOverlay("pageChoose", {
                                  disableBottomSelect: true,
                                });
                              }}
                              removeMedia={(j) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i].media.splice(j, 1);
                                editContentData[selectedLanguage][i].languages.splice(j, 1);
                                setContentData(editContentData);
                              }}
                              contentEditEnabled={contentEditEnabled}
                              onChange={(key, value) => {
                                let copyAll = cloneDeep(contentData);
                                let copyModule = copyAll[selectedLanguage][i];
                                copyModule[key] = value;
                                setContentData(copyAll);
                                setRandomNumber(Math.floor(Math.random() * 1000000));
                              }}
                              onChangeObj={(n) => {
                                let copyData = cloneDeep(contentData);
                                copyData[selectedLanguage][i] = n;
                                setContentData(copyData);
                              }}
                              setOptionsList={setOptionsList}
                              closeOptionsList={() => setListIsOpen(false)}
                              selectedLanguage={selectedLanguage}
                              chosenLanguages={chosenLanguages}
                              openInfo={(info) => openInfo(info)}
                              inEdit={contentEditEnabled}
                              editPage={editPage}
                              isArticle={isArticle}
                            />
                          </div>
                        </div>
                      );
                    }
                    if (element.type === "visit_faroe_islands_top") {
                      return (
                        <div className={" module " + (element.is_hero ? "full-width " : "")} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} customOptions={VisitFaroeIslandsTopMO} />
                            <VisitFaroeIslandsTop
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={element}
                              selectedLanguage={selectedLanguage}
                              chosenLanguages={chosenLanguages}
                              onChange={(key, value) => {
                                let copyAll = cloneDeep(contentData);
                                let copyModule = copyAll[i];
                                copyModule[key] = value;
                                setContentData(copyAll);
                                setRandomNumber(randomNumber + 1);
                              }}
                              onChangeText={(index, key, value) => {
                                let copyAll = cloneDeep(contentData);
                                let copyModule = copyAll[selectedLanguage][i];
                                let text = copyModule.languages;
                                text[index][key] = value;
                                setContentData(copyAll);
                              }}
                              openOverlay={(whatOverlay, data, innerIndex, key) => {
                                setModuleIndexInEdit(i);
                                setInnerIndex(innerIndex);
                                setModuleKeyInEdit(key);
                                props.setSiteRef(props.data.page.site_id);
                                props.openOverlay(whatOverlay, data);
                              }}
                              addSlide={() => {
                                let copyAll = cloneDeep(contentData);
                                let copyModule = copyAll[selectedLanguage][i];
                                let maxKey = 0;
                                copyModule.data.forEach((element) => {
                                  if (element.key > maxKey) maxKey = element.key;
                                });

                                copyModule.data.push({
                                  key: maxKey + 1,
                                  media: {
                                    id: undefined,
                                    extension: undefined,
                                  },
                                  button_direct: undefined,
                                  bottom_icon: "2",
                                  title_and_description: "light_text",
                                  link_option: "page",
                                });
                                chosenLanguages.forEach((e) => {
                                  copyModule.languages.push({
                                    headline: "",
                                    excerpt: "",
                                    button_active: false,
                                  });
                                });
                                setContentData(copyAll);
                              }}
                              removeSlide={(j) => {
                                let copyAll = cloneDeep(contentData);
                                let copyModule = copyAll[selectedLanguage][i];
                                copyModule.data.splice(j, 1);
                                copyModule.languages.splice(j, 1);
                                setContentData(copyAll);
                              }}
                              setOptionsList={setOptionsList}
                              closeOptionsList={() => setListIsOpen(false)}
                              onChangeObj={(n) => {
                                let copyData = cloneDeep(contentData);
                                copyData[selectedLanguage][i] = n;
                                setContentData(copyData);
                              }}
                              siteId={props.data.page.site_id}
                              inEdit={contentEditEnabled}
                              editPage={editPage}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "media_grid") {
                      let firstMediaGrid = "";
                      let lastMediaGrid = "";

                      if (contentData[selectedLanguage][i - 1]) {
                        if (contentData[selectedLanguage][i - 1].type !== "media_grid") {
                          firstMediaGrid = "firstMediaGrid";
                        }
                      }
                      if (
                        contentData[selectedLanguage].length === i + 1 ||
                        contentData[selectedLanguage][i + 1].type !== "media_grid"
                      ) {
                        lastMediaGrid = "lastMediaGrid";
                      }

                      return (
                        <div
                          className={" module disable-maxwidth " + firstMediaGrid + " " + lastMediaGrid}
                          key={element.key}
                        >
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} customOptions={VfiCarouselMO} offset={{ x: 0, y: 40 }} />

                            <MediaGrid
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={element}
                              openOverlay={(what, data, innerIndex, key) => {
                                props.setSiteRef(props.data.page.site_id);
                                props.openOverlay(what, data);
                                setModuleIndexInEdit(i);
                                setModuleKeyInEdit(key);
                                setInnerIndex(innerIndex);
                              }}
                              updateData={(data) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = data;
                                setContentData(editContentData);
                              }}
                              chosenLanguages={chosenLanguages}
                              selectedLanguage={selectedLanguage}
                              maxLength={element.grid_type === "team" ? 9999 : element.grid_type === "related" ? 5 : 10}
                              setInnerIndex={setInnerIndex}
                              onStartDrag={(inner) => {
                                mediaGridStartDrag(i, inner);
                              }}
                              onDrop={(inner) => {
                                mediaGridDrop(i, inner);
                              }}
                              isDragging={isDragging}
                              setOptionsList={setOptionsList}
                              closeOptionsList={() => setListIsOpen(false)}
                              onChangeObj={(n) => {
                                let copyData = cloneDeep(contentData);
                                copyData[selectedLanguage][i] = n;
                                setContentData(copyData);
                              }}
                              openInfo={(info) => openInfo(info)}
                              inEdit={contentEditEnabled}
                              editPage={editPage}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "whatson_grid") {
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons customOptions={WhatsonEditModule} index={i} />
                            <MediaGrid
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={{ ...element, media: element.whatson_list }}
                              customDisplay={({ data, index }) => (
                                <div className="whatson-item" draggable={false}>
                                  <img
                                    src={data.image && data.image.info ? data.image.large : noImage}
                                    draggable={false}
                                    alt="Whatson media shown"
                                  />
                                  <div className="title">{data.headline}</div>
                                </div>
                              )}
                              hideAddButton={true}
                              onStartDrag={(inner) => {
                                mediaGridStartDrag(i, inner);
                              }}
                              onDrop={(inner) => {
                                whatsonGridDrop(i, inner);
                              }}
                              isDragging={isDragging}
                              chosenLanguages={chosenLanguages}
                              selectedLanguage={selectedLanguage}
                              maxLength={8}
                              updateData={(data) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = data;
                                setContentData(editContentData);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "whatson_card") {
                      if (element.new || element.default) {
                        element.paddingBottom = 25;
                        element.paddingTop = 25;
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons customOptions={WhatsonEditModule} index={i} />
                            <CardType
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={{ ...element, media: element.whatson_list }}
                              customDisplay={({ data, index }) => (
                                <div className="whatson-item" draggable={false}>
                                  <img
                                    src={data.image.info ? data.image.large : noImage}
                                    draggable={false}
                                    alt="Whatson media shown"
                                  />
                                  <div className="title">{data.headline}</div>
                                </div>
                              )}
                              hideAddButton={true}
                              onStartDrag={(inner) => {
                                mediaGridStartDrag(i, inner);
                              }}
                              onDrop={(inner) => {
                                whatsonGridDrop(i, inner);
                              }}
                              isDragging={isDragging}
                              chosenLanguages={chosenLanguages}
                              selectedLanguage={selectedLanguage}
                              maxLength={8}
                              updateData={(data) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = data;
                                setContentData(editContentData);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "whatson_list") {
                      if (element.new || element.default) {
                        element.paddingBottom = 25;
                        element.paddingTop = 25;
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons customOptions={WhatsonEditModule} index={i} />
                            <ListType
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={{ ...element, media: element.whatson_list }}
                              customDisplay={({ data, index }) => (
                                <div className="whatson-item" draggable={false}>
                                  <img
                                    src={data.image.info ? data.image.large : noImage}
                                    draggable={false}
                                    alt="Whatson media shown"
                                  />
                                  <div className="title">{data.headline}</div>
                                </div>
                              )}
                              hideAddButton={true}
                              onStartDrag={(inner) => {
                                mediaGridStartDrag(i, inner);
                              }}
                              onDrop={(inner) => {
                                whatsonGridDrop(i, inner);
                              }}
                              isDragging={isDragging}
                              chosenLanguages={chosenLanguages}
                              selectedLanguage={selectedLanguage}
                              maxLength={8}
                              updateData={(data) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = data;
                                setContentData(editContentData);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "social") {
                      if (element.new || element.default) {
                        element.paddingBottom = 25;
                        element.paddingTop = 25;
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <SocialModule
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "whatson_module") {
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <WhatsonModule2
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={element}
                              updateData={(data) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = data;
                                setContentData(editContentData);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "download_module") {
                      if (element.new || element.default) {
                        element.paddingBottom = 40;
                        element.paddingTop = 40;
                        element.new = false;
                        element.default = false;
                      }
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} customOptions={DownloadModuleMO} />
                            <DownloadModule
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              data={element}
                              updateData={(data) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = data;
                                setContentData(editContentData);
                              }}
                              openOverlay={(what, data, index, key) => {
                                props.setSiteRef(props.data.page.site_id);
                                setModuleIndexInEdit(i);
                                setInnerIndex(index);
                                setModuleKeyInEdit(key);
                                props.openOverlay(what, data);
                              }}
                              onChange={(newData) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = newData;
                                setContentData(editContentData);
                              }}
                              setOptionsList={setOptionsList}
                              selectedLanguage={selectedLanguage}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "serviceTabs") {
                      return (
                        <div className={"module "} key={element.key}>
                          <DefaultModuleButtons index={i} />
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <ServiceTabs
                              style={{
                                marginTop: element.marginTop + "px",
                                marginBottom: element.marginBottom + "px",
                                paddingBottom: element.paddingBottom + "px",
                                paddingTop: element.paddingTop + "px",
                              }}
                              size={boxWidth}
                              values={element}
                              menues={menues}
                              contentEditEnabled={contentEditEnabled}
                              openOverlay={(what, data, innerIndex, key) => {
                                props.setSiteRef(props.data.page.site_id);
                                props.openOverlay(what, data);
                                setModuleIndexInEdit(i);
                                setModuleKeyInEdit(key);
                                setInnerIndex(innerIndex);
                              }}
                              setOptionsList={setOptionsList}
                              closeOptionsList={() => setListIsOpen(false)}
                              onChangeObj={(n) => {
                                let copyData = cloneDeep(contentData);
                                copyData[selectedLanguage][i] = n;
                                setContentData(copyData);
                              }}
                              change={(e) => {
                                let editContentData = cloneDeep(contentData);
                                editContentData[selectedLanguage][i] = {
                                  ...element,
                                  ...e,
                                };
                                setContentData(editContentData);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }

                    if (element.type === "form") {
                      return (
                        <div className={" module "} key={element.key}>
                          <div
                            className={
                              (element.backgroundColor && "backgroundColor" + element.backgroundColor) + " wrap-module"
                            }
                          >
                            <DefaultModuleButtons index={i} />
                            <div className="form">This form type is: {element.form_type}</div>
                          </div>
                        </div>
                      );
                    }

                    return <div>Module with type "{element.type}" wasn't added propperly...</div>;
                  })
                : ""}
              {contentData[selectedLanguage] && contentData[selectedLanguage].length === 0 ? (
                <div
                  className="add-module"
                  onClick={(e) => {
                    addModuleBetween(e, 0, RenderPageModules);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {env.theme === "VFI" && !isArticle ? (
                <>
                  {data.siblings.length > 0 && <AutoRelated data={data} shortLang={selectedLanguage} />}

                  <Breadcrumbs data={data} env={env.protocol + env.env} shortLang={selectedLanguage} />

                  <PageFooterDisplay
                    env={env.protocol + env.env}
                    fromDomain={data.site.domain_name}
                    shortLang={selectedLanguage}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="options-section col-lg-3">
        <div className="options-section-header">
          <ToolTip
            offset={{ x: 20, y: 30 }}
            priority={"right,bottom"}
            title=""
            delay={600}
            toolTipElements={<div>Show/edit general info</div>}
            className={"option" + (["general", "location-on-map"].includes(sideOption) ? " selected" : "")}
            onClick={() => setSideOption("general")}
          >
            <div>{generalText.headline}</div>
          </ToolTip>
          <ToolTip
            offset={{ x: 20, y: 30 }}
            priority={"right,bottom"}
            title=""
            delay={600}
            toolTipElements={<div>Show history table</div>}
            className={"option" + (sideOption === "history" ? " selected" : "")}
            onClick={() => setSideOption("history")}
          >
            <div>{languages[language].edit_page.option_section.history.headline}</div>
          </ToolTip>
          <ToolTip
            offset={{ x: 10, y: 30 }}
            priority={"right,bottom"}
            title=""
            delay={600}
            toolTipElements={<div>Show Google analytics for this page</div>}
            className={"option" + (sideOption === "analytics" ? " selected" : "")}
            onClick={() => setSideOption("analytics")}
          >
            <div>{languages[language].edit_page.option_section.analytics.headline}</div>
          </ToolTip>
        </div>
        <div className="option-section-content">{sideOptionContent}</div>
      </div>
      <RightSideInfo
        info={rightSideInfo}
        isShowing={rightSideInfo !== ""}
        close={() => {
          setRightSideInfo("");
        }}
        absolute={true}
      />
    </div>
  );
}
